import {
    BUSINESS_EVENTBRITE_CATEGORY,
    DATING_EVENTBRITE_SUB_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    HALLOWEEN_EVENTBRITE_SUB_CATEGORY,
    HEALTH_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    NIGHTLIFE_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    SEASONAL_EVENTBRITE_CATEGORY,
    SPORTS_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import {
    Arts,
    BuisnessProfession,
    FoodDrink,
    Health,
    Hobbies,
    Mic,
    Sports,
    Tickets,
} from '@eventbrite/eds-iconography';

import {
    Dating,
    DiscoBall,
    Halloween,
    HolidayCalendar,
} from '@eventbrite/eds-iconography';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import React from 'react';
import { OrganicEventSearchFiltersApiPayload } from '../../../api/searchOrganicEvents';
import { bboxToUrlParam } from '../../../utils/location';
import { buildBBox } from '../utils';
export const WEEKEND_EVENTBRITE_CATEGORY = 'this_weekend';
export const HALLOWEEN_TLDS = ['.com', '.com.au', '.co.uk'];

/****************************** Home Page Category Carousel Constants ********************************/

export const EVENTBRITE_CATEGORY_CAROUSEL_ICON_MAP: {
    [key: string]: JSX.Element;
} = {
    [BUSINESS_EVENTBRITE_CATEGORY]: <BuisnessProfession viewBox="0 0 32 33" />,
    [MUSIC_EVENTBRITE_CATEGORY]: <Mic viewBox="0 0 40 41" />,
    [PERFORMING_EVENTBRITE_CATEGORY]: <Arts viewBox="0 0 40 41" />,
    [SEASONAL_EVENTBRITE_CATEGORY]: <HolidayCalendar viewBox="0 0 28 36" />,
    [HALLOWEEN_EVENTBRITE_SUB_CATEGORY]: <Halloween viewBox="0 0 25 26" />,
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: <FoodDrink viewBox="0 0 40 41" />,
    [WEEKEND_EVENTBRITE_CATEGORY]: <Tickets viewBox="0 0 32 32" />,
    [HEALTH_EVENTBRITE_CATEGORY]: <Health viewBox="0 0 32 32" />,
    [HOBBIES_EVENTBRITE_CATEGORY]: <Hobbies viewBox="0 0 32 32" />,
    [SPORTS_EVENTBRITE_CATEGORY]: <Sports viewBox="0 0 32 32" />,
    [NIGHTLIFE_EVENTBRITE_CATEGORY]: <DiscoBall viewBox="0 0 47 48" />,
    [DATING_EVENTBRITE_SUB_CATEGORY]: <Dating viewBox="0 -5 45 45" />,
};

export const EVENTBRITE_CAROUSEL_CATEGORY_MAPPING = {
    [BUSINESS_EVENTBRITE_CATEGORY]: gettext('Business & Professional events'),
    [MUSIC_EVENTBRITE_CATEGORY]: gettext('Music events'),
    [PERFORMING_EVENTBRITE_CATEGORY]: gettext(
        'Visual & performing arts events',
    ),
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: gettext('Food & drinks events'),
    [WEEKEND_EVENTBRITE_CATEGORY]: gettext('Things to do this weekend 😎'),
};

const VIEW_MORE = gettext('View More');

/****************************** Home Page Category Carousel Constants ********************************/
export interface CATEGORY_CAROUSEL {
    key: string;
    name: GenericLazyString;
    icon: any;
    viewMore: GenericLazyString;
    eventSearch: OrganicEventSearchFiltersApiPayload;
}

export const HOME_PAGE_CATEGORY_CAROUSEL: CATEGORY_CAROUSEL[] = [
    {
        key: WEEKEND_EVENTBRITE_CATEGORY,
        name: EVENTBRITE_CAROUSEL_CATEGORY_MAPPING[WEEKEND_EVENTBRITE_CATEGORY],
        icon: EVENTBRITE_CATEGORY_CAROUSEL_ICON_MAP[
            WEEKEND_EVENTBRITE_CATEGORY
        ],
        viewMore: VIEW_MORE,
        eventSearch: {
            page_size: 6,
            dates: ['current_future', 'this_weekend'],
            aggs: {},
        },
    },
    {
        key: MUSIC_EVENTBRITE_CATEGORY,
        name: EVENTBRITE_CAROUSEL_CATEGORY_MAPPING[MUSIC_EVENTBRITE_CATEGORY],
        icon: EVENTBRITE_CATEGORY_CAROUSEL_ICON_MAP[MUSIC_EVENTBRITE_CATEGORY],
        viewMore: VIEW_MORE,
        eventSearch: {
            page_size: 6,
            tags: [MUSIC_EVENTBRITE_CATEGORY],
            aggs: {},
        },
    },
    {
        key: FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
        name: EVENTBRITE_CAROUSEL_CATEGORY_MAPPING[
            FOOD_AND_DRINK_EVENTBRITE_CATEGORY
        ],
        icon: EVENTBRITE_CATEGORY_CAROUSEL_ICON_MAP[
            FOOD_AND_DRINK_EVENTBRITE_CATEGORY
        ],
        viewMore: VIEW_MORE,
        eventSearch: {
            page_size: 6,
            tags: [FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
            aggs: {},
        },
    },
    {
        key: BUSINESS_EVENTBRITE_CATEGORY,
        name: EVENTBRITE_CAROUSEL_CATEGORY_MAPPING[
            BUSINESS_EVENTBRITE_CATEGORY
        ],
        icon: EVENTBRITE_CATEGORY_CAROUSEL_ICON_MAP[
            BUSINESS_EVENTBRITE_CATEGORY
        ],
        viewMore: VIEW_MORE,
        eventSearch: {
            page_size: 6,
            tags: [BUSINESS_EVENTBRITE_CATEGORY],
            aggs: {},
        },
    },
    {
        key: PERFORMING_EVENTBRITE_CATEGORY,
        name: EVENTBRITE_CAROUSEL_CATEGORY_MAPPING[
            PERFORMING_EVENTBRITE_CATEGORY
        ],
        icon: EVENTBRITE_CATEGORY_CAROUSEL_ICON_MAP[
            PERFORMING_EVENTBRITE_CATEGORY
        ],
        viewMore: VIEW_MORE,
        eventSearch: {
            page_size: 6,
            tags: [PERFORMING_EVENTBRITE_CATEGORY],
            aggs: {},
        },
    },
];

/****************************** Home Page Category Browse CTA Constants ********************************/

export const CATEGORY_BROWSE_KEYS = [
    MUSIC_EVENTBRITE_CATEGORY,
    NIGHTLIFE_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    SEASONAL_EVENTBRITE_CATEGORY,
    DATING_EVENTBRITE_SUB_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    BUSINESS_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
];

export const HALLOWEEN_CATEGORY_BROWSE_KEYS = [
    MUSIC_EVENTBRITE_CATEGORY,
    NIGHTLIFE_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    HALLOWEEN_EVENTBRITE_SUB_CATEGORY,
    DATING_EVENTBRITE_SUB_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    BUSINESS_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
];

interface seasonalModulelData {
    id: number;
    title: string;
    imgSrc: string;
    imgSrcMobile: string;
    bucketUrl: string;
    description: string;
}

export const SEASONAL_MODULE_DATA: { [key: string]: seasonalModulelData[] } = {
    SPD: [
        {
            id: 1,
            title: 'Bar Crawls',
            imgSrc: '',
            imgSrcMobile: '',
            bucketUrl: 'bar-crawls',
            description: '',
        },
        {
            id: 2,
            title: 'Parties',
            imgSrc: '',
            imgSrcMobile: '',
            bucketUrl: 'parties',
            description: '',
        },
        {
            id: 3,
            title: 'Festive fun',
            imgSrc: '',
            imgSrcMobile: '',
            bucketUrl: 'festive-fun',
            description: '',
        },
    ],
};

interface seasonalCarouselData {
    title: string | GenericLazyString;
    titleCSS: string;
    heapId: string;
    affCode: string;
    queryParams: any;
}

export const SEASONAL_CAROUSEL_DATA: { [key: string]: seasonalCarouselData } = {
    'Mardi Gras': {
        title: 'Mardi gras events 🎉',
        titleCSS: '',
        heapId: 'homepage-mardi-gras-carousel',
        affCode: 'ehometext',
        queryParams: {
            q: 'mardi',
            price: 'paid',
            page: 1,
            page_size: 40,
            aggs: {},
            dates: ['current_future'],
        },
    },
    SXSW: {
        title: "The best downtown events you don't need a badge for",
        titleCSS: 'wrap-title',
        heapId: 'homepage-sxsw-carousel',
        affCode: 'ehomefeatured',
        queryParams: {
            price: 'paid',
            page: 1,
            page_size: 40,
            aggs: {},
            dates: ['current_future'],
            bbox: bboxToUrlParam(
                buildBBox({ latitude: 30.271, longitude: -97.743 }),
            ),
        },
    },
};

// Lighthouse creator IDs for the top 10 metros in below order:-
// New York, London, Los Angeles, Washington DC, Chicago, Miami, Atlanta, Toronto, San Francisco, Melbourne
// Refer "packages/attendee/discover/src/__fixtures__/top10Cities.json" for Place ID to Name mapping
export const LIGHTHOUSE_ORGANIZERS: { [key: string]: string[] } = {
    '85977539': [
        '61370355243',
        '81446964593',
        '67260409013',
        '14824653214',
        '40138888083',
        '29242735213',
        '85967256683',
        '71776965133',
        '32137638673',
        '32137616519',
        '41844967623',
        '84138272793',
        '82244075583',
        '44024528493',
        '14994520016',
        '85056636653',
    ],
    '101750367': [
        '76563679213',
        '83423816363',
        '2288048001',
        '72388677353',
        '80043609393',
        '83430945923',
        '83363990493',
        '7528681429',
        '84351313713',
        '33131074383',
        '33131362385',
        '84678820323',
        '83365853443',
        '83488032753',
        '68010704573',
        '83227229823',
    ],
    '85923517': [
        '82693026393',
        '83299764033',
        '78862690423',
        '77551475723',
        '74005234593',
        '84709906413',
        '48036849503',
        '76935483753',
        '80234113653',
        '81607641513',
        '48337992853',
    ],
    '85931779': [
        '20107401396',
        '77732332923',
        '79696728103',
        '19957442984',
        '5495945187',
        '77241007553',
        '19957440970',
        '81273257093',
        '8783731711',
        '35847305833',
        '38322985123',
        '83356850863',
        '69754617103',
        '19784280271',
        '81346335083',
        '19957449026',
        '35788416473',
        '38323226803',
        '12073520151',
        '82881436093',
        '80754551393',
    ],
    '85940195': [
        '14942129834',
        '85967307033',
        '84717982553',
        '81675100443',
        '84699977393',
        '15075215961',
        '17401090910',
        '77060321543',
        '15041677826',
        '17720870809',
        '68873169863',
        '52961009013',
        '67052191623',
        '72985606813',
        '15186243753',
        '9592683007',
        '81141279673',
        '66416190563',
        '17103947364',
        '68873099373',
        '68873320913',
    ],
    '85933669': [
        '81348067123',
        '77025348433',
        '81849291303',
        '83719320513',
        '83390132213',
        '79141045363',
        '82056834003',
        '81612867843',
        '77552039643',
        '78543773523',
        '86346029663',
        '86376571973',
        '80932891093',
    ],
    '85936429': [
        '30479930378',
        '69451741713',
        '16801911812',
        '27236875801',
        '84191623653',
        '54911920463',
        '75778511243',
        '66737232233',
        '68728886903',
        '32717169187',
        '80486296663',
        '83196617023',
        '16801912819',
        '83528645063',
        '77632337823',
        '64637486183',
        '79742012893',
        '79207688623',
        '20005086168',
        '83762843053',
        '80831315003',
    ],
    '101735835': [
        '79735839983',
        '82458878753',
        '46368582813',
        '61562470703',
        '76743740883',
        '40312404253',
        '59916901793',
        '47675789653',
        '82979930763',
        '82979215793',
        '77388563263',
        '43237578133',
        '28750916413',
        '72681472673',
        '80518752273',
        '82528532943',
        '58604569323',
    ],
    '85922583': [
        '82258747573',
        '81542226793',
        '58275532073',
        '79056024353',
        '77161293433',
        '82514102633',
        '84957185333',
        '78686173393',
        '76811965133',
        '80530091093',
        '73287898143',
    ],
    '101933229': [
        '82936186683',
        '76313882793',
        '83252132933',
        '83330840053',
        '66275532803',
        '79811999393',
        '83168964803',
        '81106991323',
        '82550827923',
        '55310662253',
        '77111457003',
        '78697079203',
        '34306160809',
    ],
};
